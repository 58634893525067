@font-face {
  font-family: 'Geometria';
  src: local('☺'), url('fonts/Geometria-Medium.woff') format('woff'), url('fonts/Geometria-Medium.ttf') format('truetype'), url('fonts/Geometria-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Geometria';
  src: local('☺'), url('fonts/Geometria-Bold.woff') format('woff'), url('fonts/Geometria-Bold.ttf') format('truetype'), url('fonts/Geometria-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Geometria';
  src: local('☺'), url('fonts/Geometria-ExtaBlack.woff') format('woff'), url('fonts/Geometria-ExtaBlack.ttf') format('truetype'), url('fonts/Geometria-ExtaBlack.svg') format('svg');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Geometria';
  src: local('☺'), url('fonts/Geometria-Heavy.woff') format('woff'), url('fonts/Geometria-Heavy.ttf') format('truetype'), url('fonts/Geometria-Heavy.svg') format('svg');
  font-weight: 900;
  font-style: normal;
}
